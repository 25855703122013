




















































































.login {
  display: flex;
  padding: 24px;
  min-height: 100vh;

  &__wrapper {
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
  }

  &__logo {
    width: 300px;
    margin: 0 auto 24px auto;
  }

  &__login__form-wrapper {
    max-width: 360px;
    padding: 20px;
  }

  &__form-input-wrapper {
    max-width: 300px;
    margin-bottom: 24px;
  }

  &__remember-password-wrapper {
    text-align: center;
  }

  &__form-submit-wrapper {
    display: flex;
    justify-content: center;
  }
}

